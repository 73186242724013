<template>
  <Vue2LeafletMarkerCluster
    ref="myCluster"
    :options="{ ...clusterOptions, ...options }"
    @animationend="animationend"
    @unspiderfied="unspiderfied"
    @spiderfied="spiderfied"
    @clusterclick="() => $emit('click')"
  >
    <slot />
  </Vue2LeafletMarkerCluster>
</template>

<script>
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import '@/assets/sass/OSM/MarkerCluster.Default.sass';
import '@/assets/sass/OSM/MarkerCluster.sass';

export default {
  components: {
    Vue2LeafletMarkerCluster
  },
  data() {
    return {
      clusterOptions: {
        showCoverageOnHover: false,
        chunkedLoading: true,
        clickable: true,
        removeOutsideVisibleBounds: true,
        maxClusterRadius: this.gridSize
      }
    };
  },
  methods: {
    animationend() {},
    spiderfied() {},
    unspiderfied() {}
  },
  watch: {},
  props: {
    gridSize: { type: Number, default: 40 },
    options: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped></style>
